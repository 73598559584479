<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute, useRouter } from "vue-router"

  import { setCurrentViewContext } from "@/utils/GlobalState"
  import * as projectServices from "@/services/Project"
  import ProjectAccountAffiliationEdit from "@/components/projects/ProjectAccountAffiliationEdit.vue"
  import AccountFindAndLink from "@/components/accounts/AccountFindAndLink.vue"
  import ExpandCollapse from "@/components/ExpandCollapse.vue"
  import PageLoading from "@/components/PageLoading.vue"

  const route = useRoute()
  const router = useRouter()
  const projectCmiId = parseInt(route.params.projectCmiId as string)
  const project = ref(null)
  const active = ref("current")
  const accountList = ref(null)

  async function getData() {
    project.value = await projectServices.getProjectByCmiId(projectCmiId)
    const canEdit = projectServices.canEditProject(project.value ?? { role: null })
    if (!project.value || !canEdit) {
      return router.replace({ name: "SiteList" })
    }
    setCurrentViewContext(project.value, "project")
    await refreshAccountList()
  }

  function removeAccountFromList(accountId: number) {
    const index = accountList.value.findIndex((a: { accountId: number }) => a.accountId === accountId)
    accountList.value.splice(index, 1)
  }

  async function refreshAccountList() {
    accountList.value = await projectServices.getProjectAccountList(projectCmiId)
  }

  async function searchFunction(token: string) {
    return await projectServices.searchAccountsToLink(projectCmiId, token)
  }

  getData()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Manage Accounts & Roles") }}
    </h1>

    <div class="tabs is-toggle is-toggle-rounded is-centered">
      <ul>
        <li :class="{ 'is-active': active == 'current' }">
          <a @click="active = 'current'">
            {{ $gettext("Current Accounts") }}
          </a>
        </li>
        <li :class="{ 'is-active': active == 'new' }">
          <a @click="active = 'new'">
            {{ $gettext("Add an Account") }}
          </a>
        </li>
      </ul>
    </div>
    <template v-if="active == 'current'">
      <PageLoading
        v-if="!accountList"
        wrapper-classes="padded"
        :with-text="true"
      />
      <form v-else-if="accountList.length">
        <section class="block">
          <p class="muted is-italic">
            {{ $gettext("Accounts with a role for this project") }}
          </p>
          <ExpandCollapse>
            <template #label>
              {{ $gettext("What do the roles mean?") }}
            </template>
            <template #content>
              <div class="role-explainer columns">
                <div class="column">
                  <h3>{{ $gettext("Project Adminstrator") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Project Administrators manage the project in <em>Count Me In</em>. They have the Site Administrator role in each site in the project. They may also add new sites or members to the project.',
                      )
                    "
                  />
                </div>
                <div class="column">
                  <h3>{{ $gettext("Project Supporter") }}</h3>
                  <p>
                    {{ $gettext("Project Supporters have the same abilities as Project Administrators, but rather than manage the project, they support it. They are usually trainers.") }}
                  </p>
                </div>
                <div class="column">
                  <h3>{{ $gettext("Project Observer") }}</h3>
                  <p
                    v-html="
                      $gettext(
                        'Project Observers may only visit project and site reports. They are unable to access any child-level information, and cannot manage the project or any of its sites in <em>Count Me In</em>.',
                      )
                    "
                  />
                </div>
              </div>
            </template>
          </ExpandCollapse>
        </section>
        <section class="panel">
          <div
            v-for="account in accountList"
            :key="account.id"
            class="account block"
          >
            <ExpandCollapse>
              <template #label>
                <strong>{{ account.name }}</strong>
                <div class="muted">
                  {{ account.roleLabel }}
                </div>
              </template>
              <template #content>
                <div class="account-controls">
                  <ProjectAccountAffiliationEdit
                    :account="account"
                    @remove-account.once="removeAccountFromList"
                  />
                </div>
              </template>
            </ExpandCollapse>
          </div>
        </section>
      </form>
      <section v-else>
        <p class="muted is-italic">
          {{ $gettext("This project does not yet have any members.") }}
        </p>
      </section>
    </template>
    <template v-if="active == 'new'">
      <p
        class="context"
        v-html="
          $gettext(
            'To provide project-level access to someone who already has a <em>Count Me In</em> account, search for their account below. Otherwise, you may create a brand new account.',
          )
        "
      />
      <div
        class="columns"
        style="gap: 1em"
      >
        <div class="column box">
          <AccountFindAndLink
            :project-cmi-id="projectCmiId"
            :search-function="searchFunction"
            @refresh-account-list="refreshAccountList"
          />
        </div>
        <div class="column box">
          <h3 class="subtitle mb-4">
            {{ $gettext("Add an account") }}
          </h3>
          <router-link
            :to="{ name: 'AccountCreateForProject', params: { projectCmiId } }"
            class="button is-primary"
          >
            {{ $gettext("Create new account") }}
          </router-link>
        </div>
      </div>
    </template>
    <section v-if="!project">
      <PageLoading :with-text="true" />
    </section>
  </article>
</template>

<style scoped lang="scss">
  .panel .icon-pencil {
    fill: white;
    max-width: 1em;
    margin-right: 0.5em;
  }
  .account-controls {
    padding: 1em 0.25em;
  }
  section.block {
    padding: 0.5em 0.75em 0;
  }
  .role-explainer {
    font-size: 75%;
    h3 {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
  /* The following borrowed from .panel-block */
  .account {
    &.block {
      padding: 0.5em 0.75em;
    }
    &.block:not(:last-child) {
      margin-bottom: 0.5rem;
      border-bottom: 1px solid hsl(0deg, 0%, 93%);
    }
    strong {
      display: inline-block;
      margin-right: 1em;
    }
  }
  p.context {
    padding: 1em;
  }
  .column.box {
    margin-bottom: 1em;
  }
</style>
